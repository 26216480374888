import React from "react"
import styled from "styled-components"
import Footer from "../sections/Footer"
import { Title1, Title3, TextBold, Text } from "../assets/primitives"
import { Container, Row, Col } from "react-grid-system"
import Layout from "../components/layout"

const appPrivacyPolicy20190509 = () => (
  <Layout showCookieBanner={false}>
    <ContainerExt>
      <Row>
        <Col xs={12}>
          <Title1>Datenschutzerklärung</Title1>
          <Text>Stand: 09.05.2019</Text>
          <Text>
            Mit dieser Datenschutzerklärung erteilt die BRIVE Fleet Solution
            GmbH („BRIVE“) die Pflichtinformationen zur Datenverarbeitung gemäß
            Art. 13 und 14 der Datenschutz-Grundverordnung (DSGVO). Die Hinweise
            dienen der reinen Informationen und enthalten keinerlei eigene
            Regelungen.
          </Text>

          <Text>
            Die Datenschutzerklärung erläutert lediglich die Umstände der
            Datenverarbeitung im Einflussbereich von BRIVE. Bezüglich der
            Datenverarbeitung beim Download der BRIVE-App durch die Betreiber
            der App-Stores oder den Arbeitgeber gelten deren
            Datenschutzbestimmungen.
          </Text>
          <Title3>
            1. Name und Kontaktdaten des für die Verarbeitung Verantwortlichen
            sowie des Datenschutzbeauftragten
          </Title3>
          <Text>
            Verantwortlicher gemäß Art. 4 Nr. 7 DSGVO für die Datenverarbeitung
            bei Nutzung der BRIVE-Applikation für mobile Endgeräte
            („BRIVE-App“):
          </Text>
          <Text>
            BRIVE Fleet Solutions GmbH
            <br />
            <br />
            Residenzstraße 37
            <br />
            13409 Berlin
            <br />
            Deutschland
            <br />
            <br />
            Handelsregister: HRB 208144 B
            <br />
            Registergericht: Amtsgericht Berlin (Charlottenburg)
            <br />
            <br />
            Vertreten durch den Geschäftsführer:
            <br />
            Roman Safronov
            <br />
            <br />
            Kontakt
            <br />
            Telefon: +49152-04267406
            <br />
            E-Mail:{" "}
            <a className="dark" href="mailto:info@brive.io">
              info@brive.io
            </a>
          </Text>
          <Title3>
            2. Informationen über die Verarbeitung personenbezogener Daten
          </Title3>
          <Title3>2.1 Die Dienste von BRIVE</Title3>
          <Text>
            Der BRIVE-Dienst dient dazu, Ihr Fahrverhalten zu erfassen und für
            Ihren Arbeitgeber auszuwerten. Gutes Fahrverhalten wird im Rahmen
            einer Bonusvereinbarung mit Ihrem Arbeitgeber belohnt. Für die
            Nutzung der Dienste von BRIVE muss die Auswertung in der App
            ausdrücklich aktiviert werden.
          </Text>
          <Text>
            Es besteht keine Pflicht, BRIVE die Daten bereitzustellen. Die
            Teilnahme an der Fahrtauswertung und dem Bonussystem ist freiwillig.
            Für die Teilnahme ist die nachfolgend geschilderte Datenverarbeitung
            dann allerdings erforderlich. Der Teilnehmer kann die Fahrterfassung
            und die damit verbundene Datenverarbeitung jederzeit unterbrechen.
          </Text>
          <Title3>2.2 Registrierung in der BRIVE-App</Title3>
          <Text>
            Für die Nutzung der BRIVE-App ist eine Registrierung erforderlich,
            die innerhalb der Applikation durchgeführt werden kann. Die
            Registrierung kann nur abgeschlossen werden, wenn die als
            Pflichtfeld gekennzeichneten Felder vollständig ausgefüllt wurden.
          </Text>
          <Title3>2.3 Datenverarbeitung bei aktivierter Fahrterfassung</Title3>
          <Text>
            Wird die Fahrtenauswertung in der BRIVE-App aktiviert, erfasst diese
            über folgende Hardwarekomponenten dauerhaft Informationen:
            <ul>
              <li>GPS-Modul</li>
              <li>Beschleunigungssensor</li>
              <li>Gyroskop</li>
              <li>Magnetometer.</li>
            </ul>
          </Text>
          <Text>
            Hierbei wird insbesondere Ihr Standort, Ihre Position auf der
            Straße, Ihre Beschleunigung sowie Ihre Verzögerung erfasst. Die
            BRIVE-App erkennt anhand dieser Informationen automatisch, wenn Sie
            eine Fahrt mit einem Kraftfahrzeug unternehmen. Nur wenn dies der
            Fall ist, werden die Informationen aus den genannten
            Hardwarekomponenten an BRIVE übertragen. In allen anderen Fällen
            werden die Daten lediglich lokal in Ihrem mobilen Endgerät
            gespeichert und nach 180 Minuten gelöscht.
          </Text>
          <Title3>2.4 Datenverarbeitung im Rahmen der Auswertung</Title3>
          <Text>
            Aus den erfassten Daten erstellt BRIVE eine Bewertung Ihres
            Fahrverhaltens anhand der folgenden Parameter:
            <ul>
              <li>Anfahrverhalten</li>
              <li>Bremsverhalten</li>
              <li>Geschwindigkeit</li>
              <li>Handynutzung</li>
              <li>Kurvenverhalten</li>
              <li>
                Verbrauch (Kombination aus Geschwindigkeit, Bremsverhalten und
                Anfahrverhalten)
              </li>
            </ul>
            Im Rahmen der Auswertung können auch Verkehrsverstöße wie
            Geschwindigkeitsüberschreitungen festgestellt werden. Diese fließen
            in die Auswertung eines Teilnehmers ein, und können gesondert
            ausgewiesen. Eine Weitergabe der zu einem Teilnehmer erstellten
            Einzelauswertung an den Arbeitgeber kann in Abhängigkeit der
            Vertragsvereinbarungen erfolgen und wird entsprechend mit den
            Arbeitnehmern kommuniziert.
          </Text>
          <Text>
            Teile der dargestellten Datenverarbeitung werden von
            Auftragsverarbeitern vorgenommen. Diese Auftragsverarbeiter haben
            seinen Sitz in den USA und damit in einem Drittland nach Art. 44
            DSGVO. Ein angemessenes Datenschutzniveau wird über deren
            Zertifizierung unter dem EU-US-Privacy Shield gewährleistet.
          </Text>
          <Text>
            Der Teilnehmer kann die Fahrterfassung jederzeit in der BRIVE-App
            deaktivieren. Dann kommt es nicht zu der beschriebenen
            Datenverarbeitung.
          </Text>
          <Title3>2.5 Datenverarbeitung im Rahmen der Bonusausschüttung</Title3>
          <Text>
            Anhand der Bewertung des Fahrverhaltens des einzelnen Teilnehmers
            ermittelt BRIVE im Rahmen eines vorher mit dem Arbeitgeber
            abgestimmten Bonusprogrammes einen Scorewert für den Nutzer.
          </Text>
          <Text>
            Die Ermittlung des Scorewertes anhand Ihrer Fahrleistung erfolgt
            automatisch aufgrund vorbestimmter Parameter. Dies stellt eine
            automatische Entscheidungsfindung gemäß Art. 22 DSGVO dar. Hierbei
            wird einerseits die unter Ziff. 2.4. genannten Kriterien bewertet
            sowie andererseits die Entwicklung zum Vormonat berücksichtigt.
          </Text>
          <Text>
            Je nach Ausgestaltung des Bonusprogrammes wird diesem Scorewert ein
            bestimmter Geldbetrag oder ein anderer vom Arbeitgeber bestimmter
            Bonus („Bonuswert“) zugeordnet. BRIVE übermittelt Ihren Namen
            zusammen mit Ihrem Bonuswert an den Arbeitgeber. Aus der Höhe des
            Bonuswertes lassen sich Rückschlüsse auf die Bewertung Ihres
            Fahrverhaltens durch BRIVE ziehen.
          </Text>
          <Text>
            Hat der Arbeitgeber für die Umsetzung des Bonus einen weiteren
            Service-Partner eingeschaltet, übermittelt BRIVE für die
            Ausschüttung des Bonus ebenfalls Ihren Namen oder eine entsprechend
            eindeutige Identifikationsnummer sowie Ihren Bonuswert an diesen
            Service-Partner weiter. BRIVE erhält vom Service-Partner eine ID des
            Nutzers, um Ihm die Bonuszahlungen zuordnen zu können.
          </Text>
          <Text>
            Hat der Teilnehmer die Fahrterfassung deaktiviert, kommt es dennoch
            zu einer Verarbeitung und Weitergabe seiner Daten. Im oben
            beschriebenen Umfang wird dann der Bonuswert Null an den Arbeitgeber
            und etwaige Service-Partner mitgeteilt.
          </Text>
          <Title3>2.6 Tracking Tools</Title3>
          <Text>
            Wenn sie unsere App nutzen, erheben wir personenbezogene Daten von
            Ihnen, um die Funktion unserer App zu verbessern und unser Angebot
            individueller auf Sie abzustimmen. Ihr Verhalten in Bezug auf die
            Nutzung unserer Dienstleistung und unseres Systems wird erfasst und
            analysiert. Mit den zum Einsatz kommenden Tracking Maßnahmen wollen
            wir eine bedarfsgerechte Gestaltung und die fortlaufende Optimierung
            unserer App sicherstellen, indem wir z.B. die Häufigkeit der Nutzung
            erfassen und eine Besuchsaktionsauswertung (Conversion Tracking)
            durchführen, um die Nutzung unserer App statistisch zu erfassen und
            zum Zwecke der Optimierung unseres Angebotes für Sie auszuwerten.
            Aus diesen Informationen werden pseudonyme Nutzungsprofile erstellt,
            die nicht mit Daten über den Träger des Pseudonyms zusammengeführt
            werden. Ziel des Verfahrens ist es, zu untersuchen, woher die Nutzer
            kommen, welche Bereiche der App aufgesucht werden und wie oft und
            wie lange welche Unterseiten und Kategorien angesehen werden.
          </Text>
          <Text>
            Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus
            den folgenden Beschreibungen der entsprechenden Tracking Tools zu
            entnehmen.
          </Text>
          <Text>
            Wir löschen bzw. anonymisieren deine personenbezogenen Daten, sobald
            Sie ihre Einwilligung . Im Falle einer Anonymisierung werden
            sämtliche Daten unkenntlich gemacht, die einen Rückschluss auf deine
            Person zulassen.
          </Text>
          <TextBold>Google Analytics mit Anonymisierungsfunktion</TextBold>
          <Text>
            Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden
            Optimierung unserer App nutzen wir Google Analytics, einen
            Webanalysedienst der Google Ireland Limited, Gordon House, Barrow
            Street, Dublin 4, Irland www.google.de/intl/de/about (im Folgenden
            „Google“). In diesem Zusammenhang werden pseudonymisierte
            Nutzungsprofile erstellt und ausgewertet. Die von Google Analytics
            bei der Nutzung der App erhobenen Daten werden an einen Server von
            Google in den USA übertragen und dort gespeichert. Für die USA liegt
            ein Angemessenheitsbeschluss der Europäischen Kommission vor
            (EU/US-Privacy Shield), unter dem Google zertifiziert ist. Die
            Informationen werden verwendet, um die Nutzung der App auszuwerten,
            um Reports über die Nutzeraktivitäten zusammenzustellen und um
            weitere mit der App und der Internetnutzung verbundene
            Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten
            Gestaltung dieser App zu erbringen. Es wird in keinem Fall Ihre
            IP-Adresse mit anderen Daten von Google zusammengeführt. Google
            Analytics wird ausschließlich mit einer Anonymisierungsfunktion
            eingesetzt, bei der die IP-Adresse vor Übermittlung von Google
            innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
            Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
            gekürzt wird. Nur in Ausnahmefällen wird die volle IP-Adresse an
            Google in den USA übertragen und erst dort gekürzt.
          </Text>
          <Text>
            Weitere Informationen zum Datenschutz im Zusammenhang mit Google
            Analytics finden Sie etwa in der Google Analytics-Hilfe.
          </Text>
          <TextBold>Fabric und Crashlytics</TextBold>
          <Text>
            Zur Verbesserung der Performance unserer App nutzen wir die Tools
            Fabric und Crashlytics von Google. Diese liefern uns Analysen zu
            Systemausfällen und erleichtern die Systemwartung. Dabei werden
            Informationen unter anderem zum verwendeten Gerät, zu der
            installierten App-Version sowie andere Informationen bezüglich der
            Soft- und Hardware des von Ihnen verwendeten mobilen Geräts erhoben
            und zur Fehlerbehebung eingesetzt. Diese Daten werden unter einem
            Pseudonym erhoben und ermöglichen keinen unmittelbaren Rückschluss
            auf die Person des Nutzers.
          </Text>
          <Text>
            Nähere Informationen zur Datenverarbeitung von Fabric und
            Crashlytics finden Sie unter:{" "}
            <a className="dark" href={"https://fabric.io/terms"}>
              https://fabric.io/terms
            </a>
            .
          </Text>
          <TextBold>Firebase</TextBold>
          <Text>
            Diese App benutzt Firebase, einen Analysedienst unseres
            Auftragsverarbeiters Google Inc. ("Google"). Durch Firebase können
            wir erfassen, welche Funktionen der App Sie nutzen, wie oft Sie die
            App öffnen oder wann die App installiert oder deinstalliert wird.
            Diese Daten werden nicht mit Ihren sonstigen bei Google
            gespeicherten Daten verknüpft. Google wird die erhobenen Daten
            verwenden, um die Nutzung unserer Apps auszuwerten und um weitere
            mit der Nutzung der Apps verbundene Dienstleistungen an uns zu
            erbringen.
          </Text>
          <Text>
            Google ist unter dem EU-US-Privacy Shield zertifiziert und
            unterliegt daher einer Angemessenheitsentscheidung der
            EU-Kommission, d. h. das Datenschutzniveau bei Google ist als
            angemessen anerkannt, obwohl Google seinen Sitz in den USA hat.
            Zudem werden im vertraglichen Verhältnis mit Google die
            Standarddatenschutzklauseln der EU-Kommission verwendet.
          </Text>
          <Text>
            Weitere Informationen zum Datenschutz im Zusammenhang mit Google
            Firebase kannst du unter folgendem Link einsehen:
          </Text>
          <Text>
            <a className="dark" href={"https://policies.google.com/privacy"}>
              https://policies.google.com/privacy
            </a>
          </Text>
          <Title3>2.7 Google Maps</Title3>
          <Text>
            Die BRIVE-App nutzt über eine API den Kartendienst Google Maps von
            Google. Bei der Nutzung des Dienstes kommuniziert ihr Browser direkt
            mit Google. Diese Informationen werden in der Regel an einen Server
            von Google in den USA übertragen und dort gespeichert. Wir haben
            keinen Einfluss auf diese Datenübertragung.
          </Text>
          <Text>
            Mehr Informationen zum Umgang mit Nutzerdaten durch Google finden
            Sie in der Datenschutzerklärung von Google:
            <a
              className="dark"
              href={"https://www.google.de/intl/de/policies/privacy/"}
            >
              https://www.google.de/intl/de/policies/privacy/
            </a>
            .
          </Text>
          <Text>
            Weitere Informationen zu Google und dem Einsatz von Google Maps
            finden Sie hier:
          </Text>
          <Text>
            <ul>
              <li>
                Nutzungsbedingungen von Google:
                <a
                  className="dark"
                  href={
                    "http://www.google.de/intl/de/policies/terms/regional.html"
                  }
                >
                  http://www.google.de/intl/de/policies/terms/regional.html
                </a>
              </li>
              <li>
                Nutzungsbedingungen für Google Maps:
                <a
                  className="dark"
                  href={
                    "https://www.google.com/intl/de_US/help/terms_maps.html"
                  }
                >
                  https://www.google.com/intl/de_US/help/terms_maps.html
                </a>
              </li>
              <li>
                Datenschutzhinweise von Google (Google Privacy Policy):
                <a
                  className="dark"
                  href={"http://www.google.de/intl/de/policies/privacy/"}
                >
                  http://www.google.de/intl/de/policies/privacy/
                </a>
              </li>
            </ul>
          </Text>
          <Title3>2.8 Kommunikation über Whatsapp</Title3>
          <Text>
            Wenn Sie uns über den Dienst „WhatsApp“ der WhatsApp Ireland
            Limited, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Irland
            (WhatsApp Ltd.“) gelten die Nutzungungsbedingungen und die
            Datenschutzrichtlinie der WahtsApp Ldt., die Sie bei Ihrer
            Registrierung akzeptiert haben. Wenn Sie uns über WhatsApp
            kontaktieren, wird der Inhalt der Kommunikation Ende-zu-Ende
            verschlüsselt, so das WhatsApp Ltd. nach eigener Aussage keinen
            Zugriff darauf erhält. WhatsApp Ltd. speichert und verarbeitet aber
            Metadaten der Kommunikation, die auch personenbezogene Daten wie
            etwa Ihre Rufnummer oder Ihren Standort enthalten können. Die Daten
            werden auch mit Dritten geteilt. Da WhatsApp Ltd. zum
            Facebook-Konzern gehört, gilt dies insbesondere auch bezüglich der
            übrigen Konzernunternehmen. Die Datenempfänger können ihren Sitz
            auch außerhalb der EU, bspw. in den USA haben. Sie können Ihre Daten
            jederzeit bei WhatsApp direkt löschen.{" "}
          </Text>
          <Text>
            BRIVE hat weder genaue Kenntnis, noch Einfluss auf die
            Datenverarbeitung durch die WhatsApp Ltd., Unternehmen des
            Facebook-Konzerns oder sonstige Datenempfänger. Die vorstehende
            Darstellung beruht auf öffentlich verfügbaren Informationen der
            WhatsApp Ltd. und erhebt keinen Anspruch auf Richtigkeit und
            Vollständigkeit. Weitere Informationen zur Datenverarbeitung und die
            Zusammenarbeit mit Facebook-Unternehmen finden Sie in der
            Datenschutzrichtlinie von WhatsApp Ltd.
          </Text>
          <Title3>2.9 Rechtsgrundlagen</Title3>
          <Text>
            Die Rechtsgrundlage der Datenverarbeitung im Rahmen der
            Registrierung (Ziff. 2.2.) ist Art. 6 Abs. 1 Satz 1 lit. b DSGVO.
            Die Verarbeitung ist erforderlich für die Durchführung des
            Nutzungsverhältnisses, das durch Ihre Registrierung begründet wird.
            Die Daten werden für die Dauer des Vertragsverhältnisses
            gespeichert.
          </Text>
          <Text>
            Bezüglich der Verarbeitung Ihrer Daten im Rahmen der Erhebung,
            Auswertung und Bewertung Ihres Fahrverhaltens (Ziff. 2.3. und 2.4.)
            sowie der darauf beruhenden Bonusermittlung und -ausschüttung ist
            die Rechtsgrundlage (Ziff. 2.5.) der Datenverarbeitung Art. 6 Abs. 1
            Satz 1 lit. a DSGVO. Bei der Registrierung muss eine Einwilligung in
            die geschilderte Datenverarbeitung erteilt werden. Diese
            Einwilligung ist zwingende Bedingung Ihrer Registrierung, da die
            Auswertung erforderlich für die Durchführung des
            Nutzungsverhältnisses ist.
          </Text>
          <Text>
            Sie können die Einwilligung bezüglich der Erhebung, Auswertung und
            Bewertung jederzeit vorübergehend aussetzen, indem Sie die
            Fahrauswertung in der BRIVE-App deaktivieren. Außerdem können Sie
            jederzeit Ihre vollständige Einwilligung endgültig mit Wirkung für
            die Zukunft widerrufen. Dies führt automatisch zur Kündigung der
            Nutzungsvereinbarung mit BRIVE. Ab dem Zeitpunkt Ihres Widerrufs
            werden weder Ihre Fahrtdaten erfasst, noch Bewertungen Ihres
            Fahrverhaltens erstellt.
          </Text>
          <Text>
            Soweit wir Ihre Daten aufgrund gesetzlicher Pflichten speichern oder
            an Dritte übermitteln müssen, ist die Rechtsgrundlage hierfür Art. 6
            Abs. 1 Satz 1 lit. c DSGVO.
          </Text>
          <Text>
            {" "}
            Die Tracking-Maßnahmen werden auf Grundlage ihrer Einwilligung nach
            Art. 6 Abs. 1 Satz 1 lit. a DSGVO durchgeführt. Diese Einwilligung
            können Sie jederzeit mit Wirkung für die Zukunft in den
            App-Einstellungen unter „Einstellungen“ > Datenschutzerklärung >
            Analytics Deaktivieren oder durch Mitteilung an info@brive.io
            widerrufen.{" "}
          </Text>
          <Text>
            In einzelnen Fällen können wir Ihre Daten auch verarbeiten, um
            unsere berechtigten Interessen durchzusetzen. Dies gilt
            insbesondere, soweit eine Datenverarbeitung erforderlich ist, um
            eigene Rechtsansprüche durchzusetzen oder uns gegen solche zu
            verteidigen. Auch die Nutzung von Google Maps erfolgt aufgrund
            unseres berechtigten Interesses. Dieses besteht in der ansprechenden
            Darstellung unserer Online-Angebote und an einer leichten
            Auffindbarkeit der von uns in der App angegebenen Orte.
            Rechtsgrundlage ist insoweit jeweils Art. 6 Abs. 1 Satz 1 lit. f
            DSGVO.
          </Text>
          <Title3>3. Speicherdauer</Title3>
          <Text>
            Wir verarbeiten Ihre Daten jeweils nur solange, wie dies aufgrund
            der Rechtsgrundlage zulässig ist. Die Daten Ihrer Registrierung
            verarbeiten wir, solange diese besteht. Nach Ihrer Kündigung können
            wir Ihre in Einzelfällen aufgrund unseres berechtigten Interesses
            noch länger speichern, z.B. um uns gegen Rechtsansprüche zu
            verteidigen. Die Verarbeitung erfolgt dann solange das berechtigte
            Interesse besteht. Bei der Durchsetzung oder Verteidigung gegen
            Rechtsansprüche ist dies nicht länger als die Verjährungsfrist der
            jeweiligen Ansprüche.
          </Text>
          <Text>
            Soweit Sie uns eine Einwilligung erteilt haben, werden Ihre Daten,
            sofern in dieser Datenschutzerklärung nichts Abweichendes
            dargestellt wird, bis zum Wegfall dieser Einwilligung verarbeitet.
          </Text>
          <Title3>4. Weitergabe von Daten</Title3>
          <Text>
            Wie dargestellt, erfordert bereits die Funktionsweise der
            BRIVE-Leistungen die Weitergabe Ihrer Daten bzw. Teile davon an
            Dritte, wie Ihren Arbeitgeber oder einen in die Bonusausschüttung
            eingeschalteten Service-Partner. Daneben werden Ihre Daten auch an
            Auftragsverarbeiter weitergegeben, die von BRIVE in die
            Leistungserbringung einbezogen wurden. In Ausnahmefällen kann auch
            die gesetzliche Pflicht bestehen, die Daten an öffentliche Stellen
            oder sonstige Dritte herauszugeben.
          </Text>
          <Text>
            Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn dies im
            Rahmen der geltenden Rechtsgrundlage zulässig ist.
          </Text>
          <Title3>5. Sicherheit</Title3>

          <Text>
            Im Übrigen haben wir technische und administrative
            Sicherheitsvorkehrungen getroffen um Ihre personenbezogenen Daten
            gegen Verlust, Zerstörung, Manipulation und unautorisierten Zugriff
            zu schützen. All unsere Mitarbeiter sowie für uns tätige
            Dienstleister sind auf die gültigen Datenschutzgesetze verpflichtet.
            Unsere Sicherheitsvorkehrungen unterliegen einem ständigen
            Verbesserungsprozess und unsere Datenschutzerklärungen werden
            ständig überarbeitet. Die jeweils aktuellste Fassung kann jederzeit
            in der BRIVE-App abgerufen werden.
          </Text>
          <Title3>6. Betroffenenrechte</Title3>
          <Text>
            Sie haben das Recht:
            <ul>
              <li>
                gemäß Artikel 15 DSGVO und unter den Einschränkungen des § 34
                BDSG Auskunft über Ihre von uns verarbeiteten personenbezogenen
                Daten zu verlangen;
              </li>
              <li>
                gemäß Artikel 16 DSGVO unverzüglich die Berichtigung unrichtiger
                oder Vervollständigung Ihrer bei uns gespeicherten
                personenbezogenen Daten zu verlangen;
              </li>
              <li>
                gemäß Artikel 17 DSGVO die Löschung Ihrer bei uns gespeicherten
                personenbezogenen Daten zu verlangen, soweit nicht die
                Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung
                und Information, zur Erfüllung einer rechtlichen Verpflichtung,
                aus Gründen des öffentlichen Interesses oder zur Geltendmachung,
                Ausübung oder Verteidigung von Rechtsansprüchen erforderlich
                ist; gemäß § 35 BDSG gelten weitere Einschränkungen;
              </li>
              <li>
                gemäß Artikel 18 DSGVO die Einschränkung der Verarbeitung Ihrer
                personenbezogenen Daten zu verlangen, soweit die Richtigkeit der
                Daten von Ihnen bestritten wird, die Verarbeitung unrechtmäßig
                ist, Sie aber deren Löschung ablehnen und wir die Daten nicht
                mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung
                oder Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß
                Artikel 21 DSGVO Widerspruch gegen die Verarbeitung eingelegt
                haben;
              </li>
              <li>
                gemäß Artikel 20 DSGVO Ihre personenbezogenen Daten, die Sie uns
                bereitgestellt haben, in einem strukturierten, gängigen und
                maschinenlesebaren Format zu erhalten oder die Übermittlung an
                einen anderen Verantwortlichen zu verlangen;
              </li>
              <li>
                gemäß Artikel 7 Absatz 3 DSGVO Ihre einmal erteilte Einwilligung
                jederzeit gegenüber uns zu widerrufen. Dies hat zur Folge, dass
                wir die Datenverarbeitung, die auf dieser Einwilligung beruhte,
                für die Zukunft nicht mehr fortführen dürfen und
              </li>
              <li>
                gemäß Artikel 77 DSGVO sich bei einer Aufsichtsbehörde zu
                beschweren. In der Regel können Sie sich hierfür an die
                Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
                Arbeitsplatzes oder unseres Geschäftssitzes wenden. Eine Liste
                der zuständigen Aufsichtsbehörden mit Anschrift finden Sie hier.
              </li>
            </ul>
          </Text>

          <Title3>7. Widerspruchsrecht</Title3>
          <Text>
            Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten
            Interessen gemäß Artikel 6 Absatz 1 lit. f DSGVO verarbeitet werden,
            haben Sie das Recht, gemäß Artikel 21 DSGVO Widerspruch gegen die
            Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit dafür
            Gründe vorliegen, die sich aus Ihrer besonderen Situation ergeben
            oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren
            Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe
            einer besonderen Situation von uns umgesetzt wird. Wir dürfen die
            Verarbeitung, außer im Falle von Direktwerbung, dennoch fortführen,
            wenn wir zwingende schutzwürdige Gründe für die Verarbeitung
            nachweisen können, die gegenüber Ihren Interessen, Rechte und
            Freiheiten überwiegen.
          </Text>
          <Text>
            Möchten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch
            machen, genügt eine E-Mail an info@brive.io.
          </Text>
          <Title3>Stand und Änderung dieser Datenschutzerklärung</Title3>
          <Text>
            Aufgrund technischer Weiterentwicklung und/oder aufgrund geänderter
            gesetzlicher und/oder behördlicher Vorgaben kann es notwendig
            werden, diese Datenschutzinformationen anzupassen.
          </Text>
        </Col>
      </Row>
    </ContainerExt>
  </Layout>
)

export default appPrivacyPolicy20190509

const ContainerExt = styled(Container)`
  padding: 3rem 0;
`
